import React from "react"
import styled from "../../config"
import TextBlock from "../../components/TextBlock"
import { Flex, Box } from "reflexbox"
import Blob from "../../components/Blob"

const Werkwijze = () => (
  <WerkwijzeWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Werkwijze</h1>
    </Flex>
    <Box p={3}>
      <h2>Algemene werkwijze</h2>
      <TextBlock>
        Ik werk volgens de Oplossingsgerichte, Cognitieve en Systemische
        Therapie, ook wel <b>Oplossingsgerichte Korte Therapie</b>{" "}
        (Solution-Focused Brief Therapy) genoemd.
      </TextBlock>
      <TextBlock>
        Oplossingsgerichte Therapie is een vorm van psychotherapeutische
        hulpverlening. De kern hiervan is dat de{" "}
        <b>focus ligt op de positieve verandering in plaats van het probleem</b>
        . Een samenwerkingsrelatie tussen twee gelijkwaardige gesprekpartners.
        Samen kijken naar wat mogelijk is. Stapje voor stapje, op het tempo van
        de cliënt gaan ze op het beoogde doel af. Therapeut en cliënt
        onderzoeken de talenten en vaardigheden die de cliënt al in huis heeft
        om zijn/haar probleem aan te pakken. Het uitgangspunt is dat de cliënt
        de capaciteiten heeft om zelf doelen te formuleren, oplossingen te
        bedenken en deze uit te voeren. Daardoor kan de cliënt in de verdere
        toekomst vaak zelf moeilijke situaties constructief en creatief aan. De
        therapeut versterkt daardoor de autonomie van de cliënt.
      </TextBlock>
      <TextBlock>
        Oplossingsgericht werken hanteert de volgende <b>drie basisprincipes</b>
        :
      </TextBlock>
      <ol>
        <li>Wat niet stuk is, dien je niet te herstellen.</li>
        <li>Zoek uit wat werkt en doe hier meer van.</li>
        <li>Als iets niet werkt, stop er mee en doe iets anders.</li>
      </ol>
      <TextBlock>
        Oplossingsgerichte therapie is een taalgerichte methode.{" "}
        <b>
          De gespreksstrategie toont zich in het stellen van effectieve vragen
        </b>
        . Vragen die je helpen te focussen op de oplossing in plaats van op het
        probleem. Om een passende oplossing te vinden moeten de juiste vragen
        gesteld worden.
      </TextBlock>
      <TextBlock>
        Hieronder enkele voorbeelden om het verschil in aanpak duidelijk te
        maken:
      </TextBlock>
      <table>
        <tr>
          <th>probleemgerichte therapie</th>
          <th>oplossingsgerichte therapie</th>
        </tr>
        <tr>
          <td>probleem analyseren</td>
          <td>oplossing zoeken</td>
        </tr>
        <tr>
          <td>gericht op het verleden</td>
          <td>gericht op de toekomst</td>
        </tr>
        <tr>
          <td>waar wil ik van af</td>
          <td>waar wil ik naar toe</td>
        </tr>
        <tr>
          <td>wat is er moeilijk</td>
          <td>wat is er mogelijk</td>
        </tr>
        <tr>
          <td>focus op de klacht</td>
          <td>focus op de kracht</td>
        </tr>
        <tr>
          <td>probleem staat centraal</td>
          <td>de persoon staat centraal</td>
        </tr>
        <tr>
          <td>focus op tekortkomingen</td>
          <td>focus op talenten</td>
        </tr>
      </table>
    </Box>
  </WerkwijzeWrapper>
)

export default Werkwijze

const WerkwijzeWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`
