import React from "react"
import GlobalCollection from "../config/GlobalCollection"
import Werkwijze from "../modules/werkwijze/Werkwijze"

const WerkwijzePage = () => (
  <GlobalCollection>
    <Werkwijze />
  </GlobalCollection>
)

export default WerkwijzePage
